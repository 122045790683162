
import { Action } from "vuex-class";
import { Component, Vue, Ref } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import { PublicOffice } from "@/store/modules/public-office.store";

@Component({
    components: {
        FormBase,
        FormInput,
        FormError,
        FormSubmit,
    },
})
export default class PagePasswordForgot extends Vue {
    @Action("auth/passwordForgot") passwordForgot!: (payload: PasswordForgotPayload) => Promise<{}>;

    @Ref() form!: FormClass;

    payload: PasswordForgotPayload = {
        email: "",
    };

    completed = false;

    currentOffice: null | PublicOffice = null;

    created() {
        this.currentOffice = Vue.$cookies.get("hubr-office");
        if (this.currentOffice && this.currentOffice.theme.colors.primary) {
            document.documentElement.style.setProperty("--office-500", this.currentOffice.theme.colors.primary);
        }
    }

    mounted() {
        if (this.$route && this.$route.query && this.$route.query.email) {
            this.payload.email = this.$route.query.email as string;
        }
    }

    private submit() {
        this.passwordForgot(this.payload)
            .then(() => (this.completed = true))
            .catch((errorResponse: ErrorResponse) => (this.$errorResponse = errorResponse))
            .finally(() => this.form.reset());
    }
}
